import { Injectable, inject } from '@angular/core';
import { TSeoTags } from './seo.interface';
import { Meta, Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class SeoService {
  protected readonly _titleService = inject(Title);
  protected readonly _metaService = inject(Meta);

  removeSeoTags(): void {
    const tags = document.querySelectorAll('head meta[name^="og:"], head meta[name^="twitter:"], head meta[name^="description"], head meta[name^="keywords"]');

    tags.forEach((tag) => tag.remove());
  }

  setSeoTags(tags: TSeoTags): void {
    if (tags.title) {
      this._titleService.setTitle(`CareHive - ${tags.title}`);

      this._metaService.updateTag({
        name: 'og:title',
        content: `${tags.title} | CareHive`,
      });

      this._metaService.updateTag({
        name: 'twitter:title',
        content: `${tags.title} | CareHive`,
      });
    }

    if (tags.description) {
      this._metaService.updateTag({
        name: 'description',
        content: tags.description,
      });

      this._metaService.updateTag({
        name: 'og:description',
        content: tags.description,
      });

      this._metaService.updateTag({
        name: 'twitter:description',
        content: tags.description!,
      });
    }

    if (tags.keywords) {
      this._metaService.updateTag({
        name: 'keywords',
        content: tags.keywords,
      });
    }

    if (tags.ogImage) {
      this._metaService.updateTag({
        name: 'og:image',
        content: tags.ogImage,
      });

      if (tags.ogImageAlt) {
        this._metaService.updateTag({
          name: 'og:image:alt',
          content: tags.ogImageAlt,
        });
      }
    }
  }
}
